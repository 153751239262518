import React from 'react'
import { FluidObject } from 'gatsby-image'
import { graphql } from 'gatsby'

import { AppPageProps } from '../../types'
import { BlockList } from '../../components/BlockList'
import { breakpoints } from '../../styles/variables'
import { Button } from '../../components/Button'
import { ButtonList } from '../../components/ButtonList'
import { Gallery } from '../../components/Gallery'
import { Layout } from '../../components/Layout'
import { LoadingContext } from '../../contexts/LoadingContext'
import { ProductBlock } from '../../components/ProductBlock'
import { ProductImage } from '../../components/ProductImage'
import { ProductItem } from '../../components/ProductItem'
import { ProductRelatedList } from '../../components/ProductRelatedList'
import { SectionList } from '../../components/SectionList'
import { ShochuPageQuery } from '../../../types/graphql-types'

export const query = graphql`
  query ShochuPage {
    yamaneko1Light: file(
      relativePath: { eq: "images/products-yamaneko-1@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    yamaneko1Dark: file(
      relativePath: { eq: "images/products-yamaneko-1@dark.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    yamaneko2Light: file(
      relativePath: { eq: "images/products-yamaneko-2@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    yamaneko2Dark: file(
      relativePath: { eq: "images/products-yamaneko-2@dark.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    yamazaru1Light: file(
      relativePath: { eq: "images/products-yamazaru-1@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    yamazaru1Dark: file(
      relativePath: { eq: "images/products-yamazaru-1@dark.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    yamazaru2Light: file(
      relativePath: { eq: "images/products-yamazaru-2@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    yamazaru2Dark: file(
      relativePath: { eq: "images/products-yamazaru-2@dark.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    yamasemi1Light: file(
      relativePath: { eq: "images/products-yamasemi-1@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    yamasemi1Dark: file(
      relativePath: { eq: "images/products-yamasemi-1@dark.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    yamasemi2Light: file(
      relativePath: { eq: "images/products-yamasemi-2@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    yamasemi2Dark: file(
      relativePath: { eq: "images/products-yamasemi-2@dark.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    whiskyLight: file(
      relativePath: { eq: "images/products-whisky@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    whiskyDark: file(relativePath: { eq: "images/products-whisky@dark.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spiritsLight: file(
      relativePath: { eq: "images/products-spirits@light.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spiritsDark: file(
      relativePath: { eq: "images/products-spirits@dark.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

interface ShochuPageProps extends AppPageProps {
  data: ShochuPageQuery
}

const ShochuPage: React.FC<ShochuPageProps> = ({
  pathContext: { langKey },
  data: {
    yamaneko1Light,
    yamaneko1Dark,
    yamaneko2Light,
    yamaneko2Dark,
    yamazaru1Light,
    yamazaru1Dark,
    yamazaru2Light,
    yamazaru2Dark,
    yamasemi1Light,
    yamasemi1Dark,
    yamasemi2Light,
    yamasemi2Dark,
    whiskyLight,
    whiskyDark,
    spiritsLight,
    spiritsDark,
  },
}) => (
  <Layout
    langKey={langKey}
    pageTitle="焼酎"
    titleSublabel="焼酎"
    titleLabel="Shochu"
    headerMode="product"
  >
    <SectionList>
      <BlockList>
        <ProductBlock
          image={
            <LoadingContext.Provider value="eager">
              <Gallery>
                <ProductImage
                  fluidLight={
                    yamaneko1Light?.childImageSharp?.fluid as FluidObject
                  }
                  fluidDark={
                    yamaneko1Dark?.childImageSharp?.fluid as FluidObject
                  }
                  sizes={`(min-width: ${breakpoints.tablet}px) 50vw, 100vw`}
                />
                <ProductImage
                  fluidLight={
                    yamaneko2Light?.childImageSharp?.fluid as FluidObject
                  }
                  fluidDark={
                    yamaneko2Dark?.childImageSharp?.fluid as FluidObject
                  }
                  sizes={`(min-width: ${breakpoints.tablet}px) 50vw, 100vw`}
                />
              </Gallery>
            </LoadingContext.Provider>
          }
          sublabel="本格焼酎"
          label="尾鈴山 山ねこ"
        >
          <p>
            甘藷由来の柑橘のような爽やかさと芋の甘さ、麹の香ばしい香りがあります。すっきりとした口当たりで芋の甘みがありながら爽やかな余韻が残ります。ソーダ、水割り、ロックがおすすめです。
          </p>
          <p>
            尾鈴山 山ねこ 720ml:
            <br />
            税込価格: 1,341円
            <br />
            原料: 甘藷（ジョイホワイト）、米麹（ヒノヒカリ）
            <br />
            度数: 25度
            <br />
            容量: 720ml
          </p>
          <p>
            尾鈴山 山ねこ 1800ml:
            <br />
            税込価格: 2,672円
            <br />
            原料: 甘藷（ジョイホワイト）、米麹（ヒノヒカリ）
            <br />
            度数: 25度
            <br />
            容量: 1800ml
          </p>
          <ButtonList>
            <Button
              href="https://osuzuyama.co.jp/store/products/detail/3"
              target="_blank"
              rel="noopener noreferrer"
            >
              ONLINE STORE (720ml)
            </Button>
            <Button
              href="https://osuzuyama.co.jp/store/products/detail/21"
              target="_blank"
              rel="noopener noreferrer"
            >
              ONLINE STORE (1800ml)
            </Button>
          </ButtonList>
        </ProductBlock>
        <ProductBlock
          image={
            <Gallery>
              <ProductImage
                fluidLight={
                  yamazaru1Light?.childImageSharp?.fluid as FluidObject
                }
                fluidDark={yamazaru1Dark?.childImageSharp?.fluid as FluidObject}
                sizes={`(min-width: ${breakpoints.tablet}px) 50vw, 100vw`}
              />
              <ProductImage
                fluidLight={
                  yamazaru2Light?.childImageSharp?.fluid as FluidObject
                }
                fluidDark={yamazaru2Dark?.childImageSharp?.fluid as FluidObject}
                sizes={`(min-width: ${breakpoints.tablet}px) 50vw, 100vw`}
              />
            </Gallery>
          }
          sublabel="本格焼酎"
          label="尾鈴山 山猿"
        >
          <p>
            甘く香ばしい麦の香り、カカオのような香りが強く香ります。重厚感があり、香ばしさ甘さを強く感じる味わいで、余韻は麦の香ばしい香りが長く続きます。ロック、お湯割りがお勧めです。
          </p>
          <p>
            尾鈴山 山猿 720ml:
            <br />
            税込価格: 1,341円
            <br />
            原料: 麦、麦麹
            <br />
            度数: 25度
            <br />
            容量: 720ml
          </p>
          <p>
            尾鈴山 山猿 1800ml:
            <br />
            税込価格: 2,672円
            <br />
            原料: 麦、麦麹
            <br />
            度数: 25度
            <br />
            容量: 1800ml
          </p>
          <ButtonList>
            <Button
              href="https://osuzuyama.co.jp/store/products/detail/5"
              target="_blank"
              rel="noopener noreferrer"
            >
              ONLINE STORE (720ml)
            </Button>
            <Button
              href="https://osuzuyama.co.jp/store/products/detail/24"
              target="_blank"
              rel="noopener noreferrer"
            >
              ONLINE STORE (1800ml)
            </Button>
          </ButtonList>
        </ProductBlock>
        <ProductBlock
          image={
            <Gallery>
              <ProductImage
                fluidLight={
                  yamasemi1Light?.childImageSharp?.fluid as FluidObject
                }
                fluidDark={yamasemi1Dark?.childImageSharp?.fluid as FluidObject}
                sizes={`(min-width: ${breakpoints.tablet}px) 50vw, 100vw`}
              />
              <ProductImage
                fluidLight={
                  yamasemi2Light?.childImageSharp?.fluid as FluidObject
                }
                fluidDark={yamasemi2Dark?.childImageSharp?.fluid as FluidObject}
                sizes={`(min-width: ${breakpoints.tablet}px) 50vw, 100vw`}
              />
            </Gallery>
          }
          sublabel="本格焼酎"
          label="尾鈴山 山翡翠"
        >
          <p>
            米由来のほのかな甘み、フルーティで清涼感のある香りと熟成による穏やかなバニラ香があります。ほどよく厚みがありバランスの良い味わいです。ソーダ、ロック、水割りがお勧めです。
          </p>
          <p>
            尾鈴山 山翡翠 720ml:
            <br />
            税込価格: 1,341円
            <br />
            原料: 米（はなかぐら）、米麹（はなかぐら）
            <br />
            度数: 25度
            <br />
            容量: 720ml
          </p>
          <p>
            尾鈴山 山翡翠 1800ml:
            <br />
            税込価格: 2,672円
            <br />
            原料: 米（はなかぐら）、米麹（はなかぐら）
            <br />
            度数: 25度
            <br />
            容量: 1800ml
          </p>
          <ButtonList>
            <Button
              href="https://osuzuyama.co.jp/store/products/detail/4"
              target="_blank"
              rel="noopener noreferrer"
            >
              ONLINE STORE (720ml)
            </Button>
            <Button
              href="https://osuzuyama.co.jp/store/products/detail/23"
              target="_blank"
              rel="noopener noreferrer"
            >
              ONLINE STORE (1800ml)
            </Button>
          </ButtonList>
        </ProductBlock>
      </BlockList>
      <ProductRelatedList>
        <ProductItem
          to="/products/whisky/"
          fluidLight={whiskyLight?.childImageSharp?.fluid as FluidObject}
          fluidDark={whiskyDark?.childImageSharp?.fluid as FluidObject}
          sublabel="ウイスキー"
          label="Whisky"
          imageSizes={`(min-width: ${breakpoints.tablet}px) 20vw, 40vw`}
        />
        <ProductItem
          to="/products/spirits/"
          fluidLight={spiritsLight?.childImageSharp?.fluid as FluidObject}
          fluidDark={spiritsDark?.childImageSharp?.fluid as FluidObject}
          sublabel="スピリッツ"
          label="Spirits"
          imageSizes={`(min-width: ${breakpoints.tablet}px) 20vw, 40vw`}
        />
      </ProductRelatedList>
    </SectionList>
  </Layout>
)

export default ShochuPage
